import React from 'react';
import {
  withProps,
  defaultProps,
} from '@team-griffin/rehook';
import { Container, Row, Column, Direction, Align } from '@ux/grid';
import { Heading02, BodyCopy01 } from '@ux/typography';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import Ribbon from 'components/ribbons/Ribbon';
import CallToAction from 'components/common/CallToAction';
import PaddingBox from '@ux/padding-box';
import CenterfoldImage from './Image';
import { BreakpointProps, withBreakpointsHook } from '@ux/responsive';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import visibilitySet from 'presentation/hocs/visibilitySet';
import errorBoundary from 'presentation/hocs/errorBoundary';
import { getAgainst } from 'presentation/utils/colorway';
import flexAnchors from 'presentation/utils/flexAnchors';
import getBreakpoints from 'presentation/utils/getBreakpoints';
import { isNotNilOrEmpty } from 'crosscutting/ramda';
import { Anchor } from 'domain/constants/anchor';
import * as r from 'ramda';
import stylesheet, { Styles } from './Centerfold.stylesheet';
import * as capra from '@team-griffin/capra';
import { RibbonProps } from 'domain/constants/ribbons';
import { Dimension } from 'domain/constants/dimension';
import pureEnhance from '@ux/pure-enhance';
import { CTA } from 'core/ribbons/cta';

const setDirection = r.ifElse(
  r.path([ 'layout', 'constrainImage' ]),
  r.ifElse(
    r.path([ 'layout', 'flipped' ]),
    r.always(Direction.ROW),
    r.always(Direction.ROW_REVERSE),
  ),
  capra.nuller,
);

const needsOffset = r.both(
  r.path([ 'layout', 'flipped' ]),
  r.prop('isMinSm'),
);

const setOffset = r.ifElse(
  r.path([ 'layout', 'constrainImage' ]),
  r.always(0),
  r.always(6),
);

type text = {
  title: ShortcodeProps,
  body: ShortcodeProps,
  ctas: CTA[],
};
interface OuterProps extends RibbonProps {
  text: text,
  image: {
    url: string,
    focus: string,
    preserveDimension?: Dimension,
  }[],
}
interface DefaultProps extends OuterProps {
  hBaseline: number,
}
interface MiddleProps extends DefaultProps, BreakpointProps {}
export interface InnerProps extends MiddleProps {
  styles: Styles,
  activeImage: {
    url: string,
    focus: string,
    preserveDimension?: Dimension,
  },
  activeAnchor: Align,
  hasTitle: boolean,
  hasBody: boolean,
  hasCta: boolean,
  offset: number,
  flowDirection: Direction,
}

declare const i: number;
declare const cta: CTA;

export const PureCenterfold = ({
  id,
  styles,
  theme,
  loading,
  name,
  text,
  layout,
  activeImage,
  activeAnchor,
  colorway,
  hasTitle,
  hasBody,
  hasCta,
  offset,
  isMinSm,
  flowDirection,
  hBaseline,
}: InnerProps) => {
  const {
    title,
    body,
    ctas,
  } = r.defaultTo({} as text, text);
  const {
    constrainImage,
  } = layout;
  return (
    <Ribbon
      id={id}
      theme={theme}
      colorway={colorway}
      layout={layout}
      loading={loading}
      flush={true}
      name={name}
      type="Centerfold"
    >
      <div style={styles.root}>
        <If condition={!constrainImage}>
          <CenterfoldImage
            theme={theme}
            image={activeImage}
            layout={layout}
          />
        </If>
        <div style={styles.heightAnchor}>
          <Container
            vblock={true}
          >
            <Row
              vblock={true}
              direction={flowDirection}
            >
              <If condition={constrainImage}>
                <Column
                  xs={12}
                  sm={6}
                >
                  <CenterfoldImage
                    theme={theme}
                    image={activeImage}
                    layout={layout}
                  />
                </Column>
              </If>
              <Column
                xs={12}
                sm={6}
                offsetXs={offset}
                align={activeAnchor}
              >
                <PaddingBox
                  theme={theme}
                  style={styles.content}
                >
                  <If condition={hasTitle}>
                    <Heading02
                      depth={hBaseline}
                      theme={theme}
                      style={styles.title}
                    >
                      <Shortcode
                        theme={theme}
                        code={title}
                        against={colorway}
                      />
                    </Heading02>
                  </If>
                  <If condition={hasBody}>
                    <BodyCopy01
                      theme={theme}
                      style={styles.text}
                    >
                      <Shortcode
                        theme={theme}
                        code={body}
                        against={colorway}
                      />
                    </BodyCopy01>
                  </If>
                  <If condition={hasCta}>
                    <div style={styles.ctas}>
                      <For
                        each="cta"
                        of={ctas}
                        index="i"
                      >
                        <div
                          style={styles.action}
                          key={i}
                        >
                          <CallToAction
                            theme={theme}
                            against={getAgainst(colorway)}
                            ribbonId={id}
                            index={i}
                            block={r.not(isMinSm)}
                            {...cta}
                          />
                        </div>
                      </For>
                    </div>
                  </If>
                </PaddingBox>
              </Column>
            </Row>
          </Container>
        </div>
      </div>
    </Ribbon>
  );
};

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Centerfold', {
    hocs: [
      errorBoundary,
      visibilitySet,
    ],
    hooks: [
      defaultProps({
        hBaseline: 1,
      }),
      withBreakpointsHook,
      withProps((ownerProps: MiddleProps) => ({
        activeImage: findResponsiveItem(
          getBreakpoints(ownerProps),
          ownerProps.image
        ),
        activeAnchor: findResponsiveItem(
          getBreakpoints(ownerProps),
          flexAnchors([ Anchor.CENTER, null, null ], ownerProps.layout.anchor),
          {
            supportNull: true,
          },
        ),
        hasTitle: r.pathSatisfies(isNotNilOrEmpty, [ 'text', 'title' ], ownerProps),
        hasBody: r.pathSatisfies(isNotNilOrEmpty, [ 'text', 'body' ], ownerProps),
        hasCta: r.pathSatisfies(isNotNilOrEmpty, [ 'text', 'ctas' ], ownerProps),
        offset: r.ifElse(
          needsOffset,
          setOffset, // defaults to [6,6] layout proportions
          capra.nuller,
        )(ownerProps),
        flowDirection: r.ifElse(
          r.propEq('isMinSm', true),
          setDirection,
          capra.nuller,
        )(ownerProps),
      })),
      withProps(stylesheet),
    ],
  },
);

export default enhance(PureCenterfold);
