
import { useStyles } from '@team-griffin/stylr';
import findResponsiveItem from 'presentation/utils/findResponsiveItem';
import ribbonPadding from 'presentation/utils/ribbonPadding';
import { content } from '@ux/type-size';
import { getTitleColor, getBodyColor } from 'presentation/utils/colorway';
import { percentage, modifier } from '@ux/style-utils';
import { Alignment } from 'domain/constants/alignment';
import { gu2px } from '@ux/theme-utils';
import * as r from 'ramda';
import { InnerProps } from './Centerfold';
import { ResponsiveSet } from 'core/ribbons/primitives';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  title: CSSProperties,
  text: CSSProperties,
  ctas: CSSProperties,
  heightAnchor: CSSProperties,
  content: CSSProperties,
  action: CSSProperties,
}

export default ({
  theme,
  colorway,
  layout,
  isMinSm,
  isMinMd,
  isMinLg,
  isMinXl,
  hasCta,
  text,
}: InnerProps) => {
  const {
    spacing,
    typography,
  } = theme;

  const {
    alignment,
    flipped,
  } = layout;

  const getActiveFromArray = (array: ResponsiveSet<any>) => findResponsiveItem({
    isMinSm,
    isMinMd,
    isMinLg,
    isMinXl,
  }, array);

  const height = r.propOr([ 'auto', 'auto', 'auto' ], 'height', layout) as ResponsiveSet<any>;

  const activeHeight = getActiveFromArray(height);
  const transitionHeight = (activeHeight === 'auto') ? 200 : activeHeight;

  return useStyles({
    default: {
      root: {
        position: 'relative',
        flex: 1,
        flexBasis: 'auto',
        paddingBottom: gu2px(4, spacing),
        minHeight: transitionHeight,
      },
      title: {
        color: getTitleColor(theme, colorway),
      },
      text: {
        ...content(typography),
        color: getBodyColor(theme, colorway),
        marginTop: gu2px(2, spacing),
      },
      // 3GU between text and ctas
      ctas: {
        marginTop: gu2px(3, spacing),
        overflow: 'hidden',
      },
    },

    isMinSm: {
      root: {
        height: percentage(100),
        ...ribbonPadding({
          isMinSm,
          isMinLg,
          layout,
          spacing,
        }),
      },
      heightAnchor: {
        minHeight: activeHeight,
        display: 'flex',
        height: percentage(100),
      },
      content: {
        // 2GU between text and image (2 + 1 from Column)
        marginRight: gu2px(1, spacing),
      },
      ctas: {
        display: 'flex',
      },
    },

    flippedSm: {
      content: {
        marginRight: 0,
        // 2GU between text and image (2 + 1 from Column)
        marginLeft: gu2px(1, spacing),
      },
    },

    isMinLg: {
      content: {
        // 3GU between text and image (2 + 1 from Column)
        marginRight: gu2px(2, spacing),
      },
    },

    flippedLg: {
      content: {
        marginRight: 0,
        // 3GU between text and image (2 + 1 from Column)
        marginLeft: gu2px(2, spacing),
      },
    },
    mutipleCtas: {
      ctas: {
        marginTop: gu2px(2, spacing),
      },
      action: {
        marginTop: gu2px(1, spacing),
        ...r.ifElse(
          r.equals(true),
          r.always({
            marginRight: gu2px(1, spacing),
          }),
          r.always({})
        )(isMinSm),
      },
    },

    [modifier('alignment', Alignment.CENTER)]: {
      content: {
        textAlign: 'center',
      },
      ctas: {
        justifyContent: 'center',
      },
    },
  }, [
    { isMinSm },
    { isMinLg },
    { alignment },
    { hasCta },
    { flippedSm: r.and(flipped, isMinSm) },
    { flippedLg: r.and(flipped, isMinLg) },
    {
      mutipleCtas: r.pipe(
        r.prop('ctas'),
        // @ts-ignore
        r.length,
        r.gt(r.__, 1),
        // @ts-ignore
      )(text),
    },
  ]);
};
