import { useStyles } from '@team-griffin/stylr';
import { percentage } from '@ux/style-utils';
import { Dimension } from 'domain/constants/dimension';
import { gu2px } from '@ux/theme-utils';
import { InnerProps } from './Image';
import { CSSProperties } from 'react';
import { useBreakpoints } from '@ux/responsive';

export interface Styles {
  image: CSSProperties,
  root: CSSProperties,
}
export default ({
  image,
  layout,
  theme,
}: InnerProps) => {
  // preserving image height uses contain, so the image always fits in
  // otherwise fill available width with cover
  const aspect = image.preserveDimension === Dimension.HEIGHT ? 'contain' : 'cover';
  // calcuate as proportion of a 12 col grid
  // const imageOffset = percentage(100 - (proportions[1] / 12) * 100);
  const imageOffset = percentage(50);
  const {
    flipped,
    constrainImage,
  } = layout;
  const { isMinSm } = useBreakpoints();
  const {
    spacing,
  } = theme;

  return useStyles({
    default: {
      image: {
        position: 'static',
      },
    },
    fullBackground: {
      image: {
        width: percentage(100),
        maxWidth: percentage(100),
      },
    },
    minSmFullBackground: {
      root: {
        paddingTop: 0,
        position: 'static',
      },
      image: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: imageOffset,
        width: `calc(100% - ${imageOffset})`,
        height: percentage(100),
        objectFit: aspect,
        objectPosition: image.focus,
      },
    },

    flippedFullBackground: {
      image: {
        right: imageOffset,
        left: 0,
      },
    },

    constrained: {
      root: {
        height: percentage(100),
      },
      image: {
        position: 'absolute',
        width: percentage(100),
        height: percentage(100),
        objectFit: aspect,
        objectPosition: image.focus,
      },
    },

    constrainedXs: {
      image: {
        position: 'static',
      },
      root: {
        paddingTop: gu2px(1, spacing),
      },
    },
  }, [
    { fullBackground: !constrainImage },
    { minSmFullBackground: isMinSm && !constrainImage },
    { flippedFullBackground: flipped && isMinSm && !constrainImage },
    { constrained: constrainImage },
    { constrainedXs: constrainImage && !isMinSm },
  ]);
};