import React from 'react';
import {
  withProps,
} from '@team-griffin/rehook';
import pureEnhance from '@ux/pure-enhance';
import createImageAlt from 'presentation/utils/createImageAlt';
import stylesheet, { Styles } from './Image.stylesheet';
import urlLanguageParse from 'presentation/utils/urlLanguageParse';
import withLanguage from 'presentation/hocs/withLanguage';
import { Layout } from 'domain/constants/layout';
import { Theme } from '@ux/theme-utils';
import { Dimension } from 'domain/constants/dimension';

interface OuterProps {
  image: {
    url: string,
    focus: string,
    preserveDimension?: Dimension,
  },
  layout: Layout,
  theme: Theme,
}
export interface MiddleProps extends OuterProps {
  currentLanguage: string,
}
export interface InnerProps extends MiddleProps {
  styles: Styles,
  alt: string,
  imgSrc: string,
}

export const PureImage = ({
  styles,
  alt,
  imgSrc,
}: InnerProps) => (
  <div style={styles.root}>
    <img
      loading="lazy"
      src={imgSrc}
      style={styles.image}
      alt={alt}
    />
  </div>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'CenterfoldImage', {
    hocs: [
      withLanguage,
    ],
    hooks: [
      withProps((ownerProps: MiddleProps) => ({
        alt: createImageAlt({ imageUrl: ownerProps.image.url }),
        imgSrc: urlLanguageParse(ownerProps.image.url, ownerProps.currentLanguage),
      })),
      withProps(stylesheet),
    ],
  }
);

export default enhance(PureImage);